const routes = [
	{
		path: "/",
		alias: '/index' ,
		name: "index",
		meta: {
			title: '主页',
		},
		redirect: "/order",
		component: () => import("../views/index.vue"),
		children: [
			{
				path: "/order",
				name: "order",
				meta: {
					title: '订单管理',
					icon:'el-icon-bank-card',
				},
				component: () => import("../views/page/order.vue")
			},
			{
				path: "/user",
				name: "user",
				meta: {
					title: '用户管理',
					icon:'el-icon-user',
				},
				component: () => import("../views/page/user.vue")
			},
			{
				path: "/record",
				name: "record",
				meta: {
					title: '进店管理',
					icon:'el-icon-shopping-cart-full',
				},
				component: () => import("../views/page/record.vue")
			},
			{
				path: "/goods",
				name: "goods",
				meta: {
					title: '商品管理',
					icon:'el-icon-box',
				},
				component: () => import("../views/page/goods/index.vue"),
			},
			{
				path: "/activity",
				name: "activity",
				meta: {
					title: '限时活动管理',
					icon:'el-icon-present',
				},
				component: () => import("../views/page/activity/index.vue"),
			},
		]
	},
];

export default routes;