import axios from 'axios';
import store from '@/store/index';
import { Message } from 'element-ui';
import router from '../router/index'

// let apiHost = window.location.origin;

// 测试：api-test.ekkang.com admin-api-test.ekkang.com admin-test.ekkang.com 
// 正式：api.ekkang.com admin-api.ekkang.com admin.ekkang.com
// if (apiHost.includes('admin.ekkang.com')) {
//     apiHost = 'https://admin-api.ekkang.com';
// } else{
//     apiHost = 'https://admin-api-test.ekkang.com';
// }
// let apiHost = 'https://api-1199007-1307166845.ap-shanghai.run.tcloudbase.com';
let apiHost = location.hostname.includes("localhost")
  ? "http://127.0.0.1"
  : "https://api-100020933713.run.zyxqj.cn";

axios.defaults.timeout = 300000;// 响应时间
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';
// axios.defaults.headers['Access-Control-Allow-Origin'] = '*';
// axios.defaults.headers['Origin'] = '*';

axios.defaults.baseURL = `${apiHost}/`;
// axios.defaults.withCredentials = true 

//取消pending
let pending = [],
    cancelToken = axios.CancelToken;
let cancelPending = (config) => {
    pending.forEach((item, index) => {
        if (!!config) {
            if (item.u == config.url) {
                item.f(); //取消请求
                pending.splice(index, 1); //移除当前请求记录
            };
        } else {
            item.f(); //取消请求
            pending.splice(index, 1); //移除当前请求记录
        }
    });
};

// POST传参序列化(添加请求拦截器)
axios.interceptors.request.use(
    config => {
        // if (config.url.indexOf('user/login') < 0 ) {
        //     setRequestHeader(config);
        //     cancelPending(config);
        //     config.cancelToken = new cancelToken((c) => {
        //         pending.push({
        //             'u': config.url,
        //             'f': c
        //         });
        //     });
        //     return config;

        // } else {
        //     // if (config.method === 'post') {
        //     //     config.data = JSON.stringify(config.data);
        //     // }
        //     // setRequestHeader(config);
        //     return config;
        // }

        return config;
    },
    err => {
        return Promise.reject(err)
    }
);

// http response 拦截器
axios.interceptors.response.use(
    res => {
        let code = res.data.code
        
        if (code && code !== 200) {
            if (code == 401) {

                Message({
                    type: 'error',
                    message: '您的登录状态已发生改变，请重新登录'
                });
                // store.dispatch(types.USER_SIGN_OUT); //状态变化并且传值
                // store.commit(types.USER_SIGN_OUT);    //状态变化
                router.replace({path: '/login'});
            }
            return Promise.reject(res.data);

        }
        cancelPending(res.config);
        return res.data.data;
    },
    error => {
        // console.log(333, error.response)
        if (error.response.status == 401) {
            Message({
                type: 'error',
                message: '您的登录状态已发生改变，请重新登录'
            });
            router.replace({ path: '/login' });
        } else {
            Message({
                type: 'error',
                message: error.response.data.message
            });
        }
        return Promise.reject(error.response.data)
    }
);

function setRequestHeader(config) {
    config.headers['Authorization'] = 'Bearer ' + store.state.user.token;
}


export default axios;
export { apiHost };