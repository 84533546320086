import * as types from '../types'
import router from '../../router/index'
import util from '../../utils/util'

export default {
    state: {
        token: window.localStorage.getItem('token'),
        //用户信息
        userInfo: JSON.parse(window.localStorage.getItem('userInfo')),
    },
    getters: {
        [types.USER_IS_LOGIN] (state) {
            return Boolean(state.token);
        },
        //用户信息
        [types.USER_INFO] (state) {
            return state.userInfo;
        },
    },
    mutations: {
        // 登录
        [types.USER_SIGN_IN] (state, userInfo) {
            window.localStorage.setItem('token', userInfo.token);
            window.localStorage.setItem('userName', userInfo.userName);
            state.token = userInfo.token;
        },
        // 退出
        [types.USER_SIGN_OUT] (state) {
            window.localStorage.removeItem('token');
            window.localStorage.removeItem('userInfo');
            state.token = '';
            state.userInfo = {
                "user_number": "",
                "name": "",
                "phone": "",
                "email": "",
                "type": 0,
                "avatar": "",
                "status": 1
            }
            
            util.deleteCookie('psw');
        },
        //用户信息
        [types.USER_INFO](state, userInfo) {
            window.localStorage.setItem('userInfo', JSON.stringify(userInfo));//不需要重新输入用户信息登录的时候保存session_id
            state.userInfo = userInfo;
        },
    },
    actions: {
        // 登录
        [types.USER_SIGN_IN] ({commit}, userInfo) {
            commit(types.USER_SIGN_IN, userInfo);
            router.replace([0,2,3].includes(userInfo.type)? '/home' : userInfo.type == 1 ? '/staff/doctors' : userInfo.type == 4 ? '/staff/users' : '/home');
        },
        // 退出登录
        [types.USER_SIGN_OUT]({ commit }) {
            commit(types.USER_SIGN_OUT);
            router.replace({path: '/login'});
        },
        //用户信息
        [types.USER_INFO]({ commit }, userInfo) {
            commit(types.USER_INFO, userInfo);
        }
    }
}
