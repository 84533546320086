import {
    Row,
    Col,
    Tooltip,
    Dialog,
    Loading,
    MessageBox,
    Message,
    Notification,
    Popover,
  
    Menu,
    Submenu,
    MenuItem,
    MenuItemGroup,

    Breadcrumb,
    BreadcrumbItem,

    Dropdown,
    DropdownMenu,
    DropdownItem,

    Radio,
    RadioGroup,
    RadioButton,
    
    Table,
    TableColumn,
    Pagination,

    Form,
    FormItem,
  
    Input,
    InputNumber,
    Autocomplete,

    Button,
    ButtonGroup,

    Select,
    Option,
    OptionGroup,

    DatePicker,
    TimeSelect,
    TimePicker,

    Tabs,
    TabPane,

    Upload,

} from 'element-ui';

// 这里的 install 方法表示在 main.js 中，如果使用 Vue.use() 方法的话，则该方法默认会调用 install 方法
const element = { 
    install: function (Vue) {
        Vue.use(Row);
        Vue.use(Col);
        Vue.use(Tooltip);
        Vue.use(Dialog);
        Vue.use(Loading.directive);
        Vue.use(Popover);

        Vue.use(Menu);
        Vue.use(Submenu);
        Vue.use(MenuItem);
        Vue.use(MenuItemGroup);

        Vue.use(Breadcrumb);
        Vue.use(BreadcrumbItem);

        Vue.use(Dropdown);
        Vue.use(DropdownMenu);
        Vue.use(DropdownItem);

        Vue.use(Radio);
        Vue.use(RadioGroup);
        Vue.use(RadioButton);

        Vue.use(Table);
        Vue.use(TableColumn);
        Vue.use(Pagination);

        Vue.use(Form);
        Vue.use(FormItem);

        Vue.use(Input);
        Vue.use(InputNumber);
        Vue.use(Autocomplete);

        Vue.use(Button);
        Vue.use(ButtonGroup);

        Vue.use(Select);
        Vue.use(Option);
        Vue.use(OptionGroup);

        Vue.use(DatePicker);
        Vue.use(TimeSelect);
        Vue.use(TimePicker);

        Vue.use(Tabs);
        Vue.use(TabPane);
        
        Vue.use(Upload);
        Vue.prototype.$loading = Loading.service;
        Vue.prototype.$msgbox = MessageBox;
        Vue.prototype.$alert = MessageBox.alert;
        Vue.prototype.$confirm = MessageBox.confirm;
        Vue.prototype.$prompt = MessageBox.prompt;
        Vue.prototype.$notify = Notification;
        Vue.prototype.$message = Message;
    }
}
export default element