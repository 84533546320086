<template>
	<div id="app">
		<router-view />
	</div>
</template>
<script>
export default {

}
</script>
<style lang="scss">
	#app {
		min-width: 1440px;
	}
</style>
