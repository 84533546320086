import * as types from '../types'

export default {
    state: {
        shop_id: window.localStorage.getItem('order_shop_id') || '',
        status: window.localStorage.getItem('order_status') || '',
    },
    getters: {
        [types.ORDER_SHOP_ID](state) {
            return state.shop_id;
        },
        [types.ORDER_STATUS](state) {
            return state.status;
        },
    },
    mutations: {
        [types.ORDER_SHOP_ID](state, shop_id) {
            window.localStorage.setItem('order_shop_id', shop_id);
            state.shop_id = shop_id;
        },
        [types.ORDER_STATUS](state, status) {
            window.localStorage.setItem('order_status', status);
            state.status = status;
        },
    },
    actions: {
        [types.ORDER_SHOP_ID]({ commit }, shop_id) {
            commit(types.ORDER_SHOP_ID, shop_id);
        },
        [types.ORDER_STATUS]({ commit }, status) {
            commit(types.ORDER_STATUS, status);
        }
    }
}
