import Vue from "vue";
import VueRouter from "vue-router";
import util from '@/utils/util.js';
import routes from './routes';

// vuex store
import store from '../store/index';
import * as storeTypes from '../store/types';

Vue.use(VueRouter);

const router = new VueRouter({
	mode: "history", //直接地址跳入404 /test,需要服务端映射
	// mode: "hash",
	base: process.env.BASE_URL,
	routes
});
router.beforeEach(function (to, from, next) {
	// // 默认需求登录
	// let { auth = true } = to.meta;
	// let isLogin = store.getters[storeTypes.USER_IS_LOGIN];
	// // 如果访问的页面需要登录，而且当前页面不是登录页，跳转到登录页
    // if (auth && !isLogin && to.path !== '/login') {
    //     return next({
    //         path: '/login'
    //     });
	// }
	
	next()//必须写，调到原来的url
})
router.afterEach(function (to, from) {
	// 只用于获取你从哪里来的路由信息
	util.toDocTop()
})
export default router;
