export default {
    /**
     * 验证
     */
     checkPhone() {
        return /^1[34578]\d{9}$/;
    },
    checkEmail() {
        return  /[a-zA-Z0-9]{1,15}@[a-zA-Z0-9]{1,10}\.[a-zA-Z0-9]{1,5}/;///^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-]+)$/
    },

    /**
     * cookie
     */
     setCookie(name, value, day) {
        var d = new Date();
        d.setDate(d.getDate() + day);
        document.cookie = name + "=" + value + ";expires=" + d;
    },
     /**
     * 获取cookie
     */
    getCookie() {
        //设置了cookie后，它的显示方式是name=value; expires=day;
        //先用; 来分割字符串(记住;后面有空格)
        //cookieArr = "name=value","expires=day"
        var cookieArr = document.cookie.split("; ");
        var info = {}; //对象
        for (var i = 0; i < cookieArr.length; i++) {
            //cookieArr[1] = "name=value";
            //cookieArr[2] = "expires=day";
            //再用=来分割，c = cookieArr[1].split("=") = "name","value";
            //再循环，c = cookieArr[2].split("=") = "expires","day";
            var c = cookieArr[i].split("=");
            //那么info[c[0]] = "name"
            //c[1] = "value"
            //这样一一配对
            info[c[0]] = c[1];
        }

        return info; //最后返回给外部调用
    },
    /**
     * 通过Name 获取cookie对应的值
     */
    getCookieByName(name) {
        return this.getCookie()[name];
    },
    /**
     * 删除cookie
     */
    deleteCookie(name) {
        this.setCookie(name, '', -1);
    },

    getLocalTime (config) {

        let { dateJoin = '-' } = config;        // 默认日期连接符：-
        let { timeJoin = ':' } = config;        // 默认时间连接符：:
        let { timeType = 'seconds' } = config;      // 默认精确到分钟（minutes：精确到分钟；seconds：精确到秒）
        let { dataType = 'all' } = config;          // 默认类型为日期+时间（all：日期+时间；date：日期；time：时间）

        if (config.data) {
            let d = new Date(parseInt(config.data) * 1000);
            let date = d.getFullYear() + dateJoin + ((d.getMonth() + 1) < 10 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1)) + dateJoin + (d.getDate() < 10 ? '0' + d.getDate() : d.getDate());
            let time;

            // 获取时间（精确到秒）
            if (timeType == 'seconds') {
                time = (d.getHours() < 10 ? '0' + d.getHours() : d.getHours()) + timeJoin + (d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes()) + timeJoin + (d.getSeconds() < 10 ? '0' + d.getSeconds() : d.getSeconds());
            } else {
                time = (d.getHours() < 10 ? '0' + d.getHours() : d.getHours()) + timeJoin + (d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes());
            }

            // 返回日期+时间
            if (dataType === 'all') {
                return date + ' ' + time;
                // 返回日期
            } else if (dataType === 'date') {
                return date;
                // 返回时间
            } else if (dataType === 'time') {
                return time;
            }
        } else {
            return '';
        }
    },

    getRequestTime(time) {
        return (new Date(time).getTime() / 1000).toFixed(0);
    },



    // 函数防抖
    debounce(func, delay) {
        let timer = null;
        // 闭包函数可以访问timer
        return function (...args) {
            // 如果事件被触发，清除timer并重新开始计时
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => {
                func.apply(this, args)
            }, delay)
        }
    },
    // 回到顶部
    toDocTop() {
        return document.documentElement.scrollTop = document.body.scrollTop =0;
    },

    // 性别
    getGender(gender) {
        return gender == 1 ? '男' : gender == 2 ? '女' : '';
    },
    // 支付状态pay_status
    getPayStatus(pay_status) {
        switch (pay_status) {
            case 0:
                return '待支付';
            case 1:
                return '支付成功';
            case 2:
                return '支付失败';
            case 3:
                return '订单取消';
            case 4:
                return '订单过期';
            case 5:
                return '支付中';
            case 6:
                return '退款成功';
            case 7:
                return '退款失败';
            default:
                return '';
                break;
        } 
    },
    getPrice(price) {
        return '¥' + (price / 100).toFixed(2);
    },
    getProfitRatio(radio) {
        return (radio * 100).toFixed(2) + '%';
    }
}